@import "~react-grid-layout/css/styles.css";
@import "~react-resizable/css/styles.css";

/*! react-sidenav v0.5.0 | (c) 2020 Trend Micro Inc. | MIT | https://github.com/trendmicro-frontend/react-sidenav */
.sidenav---sidenav---_2tBP {
  position: absolute;
  top: 64px;
  bottom: 0;
  left: 0;
  z-index: 1006;
  min-width: 64px;
  background: #3d66dbd6;
  -webkit-transition: min-width 0.2s;
  -moz-transition: min-width 0.2s;
  -o-transition: min-width 0.2s;
  -ms-transition: min-width 0.2s;
  transition: min-width 0.2s;
  height: 100vh;
}
.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv .sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ- .sidenav---sidenav-subnav---1EN61 {
  display: none;
}
.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv .sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---sidenav-subnav---1EN61 {
  display: block;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
  min-width: 150px;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- .sidenav---navicon---3gCRo + .sidenav---navtext---1AE_f {
  visibility: visible;
  opacity: 1;
  -ms-filter: none;
  filter: none;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  -ms-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---expandable---3_dr7 > .sidenav---navitem---9uL5T {
  cursor: pointer;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 {
  position: static;
  border: none;
  float: left;
  clear: both;
  width: 100%;
  background: transparent;
  overflow: hidden;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 {
  display: block;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 {
  display: none;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 {
  position: relative;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47::before {
  content: " ";
  width: 100%;
  height: 28px;
  position: absolute;
  top: 0;
  z-index: -1;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:first-child {
  display: none;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:hover::before {
  background: #fff;
  opacity: 0.15;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=15)";
  filter: alpha(opacity=15);
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47::before {
  content: " ";
  width: 100%;
  height: 28px;
  position: absolute;
  top: 0;
  z-index: -1;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y > .sidenav---navitem---9uL5T {
  color: #fff;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y > .sidenav---navitem---9uL5T::before {
  content: " ";
  width: 2px;
  height: 20px;
  left: 10px;
  top: 4px;
  position: absolute;
  border-left: 2px #fff solid;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:hover > .sidenav---navitem---9uL5T {
  background: transparent;
  color: #fff;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 > .sidenav---navitem---9uL5T {
  color: #f9dcdd;
  padding: 0 14px 0 20px;
  line-height: 28px;
}
.sidenav---sidenav-toggle---1KRjR {
  position: relative;
  float: left;
  width: 64px;
  height: 64px;
  padding: 0;
  margin: 0;
  background-color: transparent;
  background-image: none;
  border: 0;
  border-radius: 0;
  cursor: pointer;
}
.sidenav---sidenav-toggle---1KRjR:focus {
  outline: none;
}
.sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02 {
  display: block;
  width: 20px;
  height: 2px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 1px;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  -ms-transition: all 0.15s;
  transition: all 0.15s;
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
}
.sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02 + .sidenav---icon-bar---u1f02 {
  margin-top: 4px;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02 {
  width: 25px;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02:nth-child(2) {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02:nth-child(3) {
  margin-top: -8px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.sidenav---sidenav-nav---3tvij {
  float: left;
  padding: 0;
  margin: 0;
  clear: both;
  list-style: none;
  width: 100%;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- {
  clear: both;
  position: relative;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T {
  cursor: default;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T::after {
  background: #fff;
  opacity: 0.15;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=15)";
  filter: alpha(opacity=15);
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T::after,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T::after {
  background: #000;
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u.sidenav---expanded---1KdUL > .sidenav---navitem---9uL5T::after,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u.sidenav---expanded---1KdUL > .sidenav---navitem---9uL5T::after {
  background: #000;
  opacity: 0.25;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=25)";
  filter: alpha(opacity=25);
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u.sidenav---selected---1EK3y.sidenav---expanded---1KdUL > .sidenav---navitem---9uL5T::after,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u.sidenav---selected---1EK3y.sidenav---expanded---1KdUL > .sidenav---navitem---9uL5T::after {
  background: #000;
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f {
  color: #fff;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo > *,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f > *,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo > *,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f > * {
  color: #fff;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T {
  position: relative;
  display: block;
  line-height: 50px;
  height: 50px;
  white-space: nowrap;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T:focus {
  outline: 0;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T::after {
  content: '';
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  z-index: -1;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f {
  color: #f9dcdd;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo > *,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f > * {
  color: #f9dcdd;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo {
  display: block;
  float: left;
  width: 64px;
  height: 50px;
  margin-right: -6px;
  vertical-align: top;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
  line-height: 50px;
  text-align: center;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo + .sidenav---navtext---1AE_f {
  width: 0;
  visibility: hidden;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transition: visibility 0s 0.2s, opacity 0.2s linear;
  -moz-transition: visibility 0s 0.2s, opacity 0.2s linear;
  -o-transition: visibility 0s 0.2s, opacity 0.2s linear;
  -ms-transition: visibility 0s 0.2s, opacity 0.2s linear;
  transition: visibility 0s 0.2s, opacity 0.2s linear;
}
.sidenav---sidenav-subnav---1EN61 {
  min-width: max-content;
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
  top: 0;
  left: 64px;
  background: #fff;
  border: 1px #bbb solid;
  -webkit-box-shadow: 2px 2px 10px rgba(0,0,0,0.15);
  box-shadow: 2px 2px 10px rgba(0,0,0,0.15);
}
.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:first-child {
  margin: 0 24px;
  line-height: 48px;
  border-bottom: 1px #ccc solid;
  font-size: 14px;
  font-weight: normal;
}
.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:nth-child(2) {
  margin-top: 8px;
}
.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:last-child {
  margin-bottom: 8px;
}
.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y > .sidenav---navitem---9uL5T {
  color: #000000;
  font-weight: bold;
  background: none;
  cursor: default;
}
.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y > .sidenav---navitem---9uL5T:hover {
  background: #fff;
}
.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 > .sidenav---navitem---9uL5T {
  display: block;
  text-decoration: none;
  color: #222;
  font-size: 13px;
  line-height: 30px;
  padding: 0 24px;
  cursor: pointer;
}
.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 > .sidenav---navitem---9uL5T:hover {
  background: #eee;
}
.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 > .sidenav---navitem---9uL5T:focus {
  outline: 0;
}
.sidenav---sidenav---_2tBP {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 20px;
}
.sidenav---sidenav---_2tBP *,
.sidenav---sidenav---_2tBP *:before,
.sidenav---sidenav---_2tBP *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar {
  position: relative;
  min-height: 50px;
  border: 1px solid transparent;
}
@media (min-width: 768px) {
  .navbar {
    border-radius: 4px;
  }
}
@media (min-width: 768px) {
  .navbar-header {
    float: left;
  }
}


.navbar-fixed-top {
  top: 0;
  border-width: 0 0 1px;
}
.navbar-fixed-bottom {
  bottom: 0;
  margin-bottom: 0;
  border-width: 1px 0 0;
}
.navbar-brand {
  float: left;
  height: 50px;
  padding: 15px 15px;
  font-size: 18px;
  line-height: 20px;
}
.navbar-brand,
.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}
.navbar-brand > img {
  display: block;
}
.navbar-default {
  background-color: #f8f8f8;
  border-color: #e7e7e7;
}
.navbar-default .navbar-brand {
  color: #777;
}
.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
  color: #5e5e5e;
  background-color: transparent;
}
.navbar-default .navbar-text {
  color: #777;
}
.navbar-default .navbar-nav > li > a {
  color: #777;
}
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
  color: #333;
  background-color: transparent;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  color: #555;
  background-color: #e7e7e7;
}
.navbar-default .navbar-nav > .disabled > a,
.navbar-default .navbar-nav > .disabled > a:hover,
.navbar-default .navbar-nav > .disabled > a:focus {
  color: #ccc;
  background-color: transparent;
}
.navbar-default .navbar-toggle {
  border-color: #ddd;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #ddd;
}
.navbar-default .navbar-toggle .icon-bar {
  background-color: #888;
}
.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  border-color: #e7e7e7;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  color: #555;
  background-color: #e7e7e7;
}
@media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #777;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #333;
    background-color: transparent;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a,
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #555;
    background-color: #e7e7e7;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a,
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #ccc;
    background-color: transparent;
  }
}
.navbar-default .navbar-link {
  color: #777;
}
.navbar-default .navbar-link:hover {
  color: #333;
}
.navbar-default .btn-link {
  color: #777;
}
.navbar-default .btn-link:hover,
.navbar-default .btn-link:focus {
  color: #333;
}

.main {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 60px;
    overflow: inherit;
    transition: all .15s;
    padding: 0 20px;
    background: 'ghostwhite' ;
    transition: background-color .35s cubic-bezier(.4, 0, .2, 1);
    width: 90vw;
    height: 100vh;
}

.maintoggle {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 150px;
  overflow: inherit;
  transition: all .15s;
  padding: 0 20px;
  background: 'ghostwhite' ;
  transition: background-color .35s cubic-bezier(.4, 0, .2, 1);
  width: 90vw;
  height: 100vh;
}
/*# sourceMappingURL=react-sidenav.css.map*/


.site-header {
    position: relative;
    padding: 0 0;
    height: 64px;
    width: 100%;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 1px #ddd solid;
}
.site-header a:link,
.site-header a:hover,
.site-header a:active,
.site-header a:visited,
.site-header a:focus {
    color: #222;
}
.site-header .banner,
.site-header .banner:hover {
    text-decoration: none;
}

.site-title {
    margin: 14px 0 0 42px;
    line-height: 36px;
    font-family: "Interstate-Light", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 26px;
    font-weight: 200;
    letter-spacing: -0.03em;
    white-space: nowrap;
}
.btn {
    display: inline-block;
    min-width: 80px;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 5px 12px;
    font-size: 13px;
    line-height: 20px;
    border-radius: 3px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.btn-border {
    color: #222222;
    background-image: linear-gradient(to bottom, #fff 0%, #fff 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffffffff', GradientType=0);
    border-color: #cccccc;
    background-color: #fff;
    transition: all 0.2s linear 0s;
    background-image: none!important;
}
.btn-border:hover,
.btn-border.hover {
    color: #222222;
    background-color: #f4f4f4;
    background-image: linear-gradient(to bottom, #f4f4f4 0%, #f4f4f4 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff4f4f4', endColorstr='#fff4f4f4', GradientType=0);
    border-color: #999999;
}
.container {
    padding: 20px;
    overflow: auto;
    position: relative;
    height: 100vh;
}
section {
    background: #fff;
    border: 1px solid #d6d6d6;
    height: 100%;
    position: relative;
    padding: 0 16px;
}
.typography-example .row {
    margin: 0 0 24px 0;
}
.typography-example .row:first-of-type {
    margin-top: 24px;
}
.typography-example .row > label {
    display: inline-block;
    width: 95px;
    margin-bottom: 0;
    vertical-align: middle;
}
.typography-example .row span,
.typography-example .row h1,
.typography-example .row h2,
.typography-example .row h3,
.typography-example .row h4,
.typography-example .row h5,
.typography-example .row h6 {
    display: inline-block;
    margin: 0 !important;
    vertical-align: middle;
}
@media (max-width: 1325px) {
  .typography-example .row {
      margin-bottom: 6px;
  }
  .typography-example .row > label,
  .typography-example .row span,
  .typography-example .row h1,
  .typography-example .row h2,
  .typography-example .row h3,
  .typography-example .row h4,
  .typography-example .row h5,
  .typography-example .row h6 {
      display: block;
  }
  .typography-example .row:first-of-type {
      margin-top: 12px;
  }
}
@media (max-width: 1240px) {
    .typography-example .row {
        margin-bottom: 5px;
    }
}
@media (max-width: 1075px) {
    .typography-example .row {
        margin-bottom: 1px;
    }
}
.example {
    position: relative;
    padding: 45px 0 15px;
    margin-bottom: 20px;
}
.example:after {
    position: absolute;
    top: 15px;
    left: 0;
    font-size: 14px;
    color: #888;
    text-transform: uppercase;
    letter-spacing: 1px;
    content: "Example";
}
.example:last-child {
    margin-bottom: 0;
}
.navoptions-right {
  margin-top: 5px;
  float: right;
}
/* This class wraps all content so height and width are set to 100 */
.content-wrapper {
  height: 100vh;
  width: 100%;
}

.img-none {
  display: none;
}
.img-uploaded {
  max-width: 40vw;
  max-height: 40vh;
}
.fbcheckbox input:checked {
  color: #3b5998;
}
